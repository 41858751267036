
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { useAppBase } from '@/core/composables/AppBase';
import { IGrauInstrucao } from '@/models/Entidades/IGrauInstrucao';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import ServicoGrauInstrucao from '@/servicos/ServicoGrauInstrucao';
import { IResposta } from '@/core/models/IResposta';

export default defineComponent({
  name: 'GrauInstrucaoCadastro',
  components: {
    ComunicacaoApi,
  },
  setup() {
    const {
      appBase, apresentarComunicacaoApi, apresentarRespostaRedirecionamento, obterCodigoRota,
    } = useAppBase();
    const servicoGrauInstrucao = new ServicoGrauInstrucao();

    const state = reactive({
      grauInstrucao: {} as IGrauInstrucao,
    });

    async function obterGrauInstrucao(codigo:number) {
      appBase.carregando = true;
      state.grauInstrucao = await servicoGrauInstrucao.obter(codigo);
      appBase.carregando = false;
    }

    function limparDados() {
      state.grauInstrucao = {} as IGrauInstrucao;
      state.grauInstrucao.codigo = 0;
      state.grauInstrucao.status = true;
    }

    onBeforeMount(async () => {
      limparDados();
      const codigoGrauInstrucao = obterCodigoRota();
      if (codigoGrauInstrucao > 0) {
        await obterGrauInstrucao(codigoGrauInstrucao);
      }
    });

    async function salvar() {
      appBase.resposta = {} as IResposta;
      apresentarComunicacaoApi('Aguarde por favor... Estamos salvando as informações do Grau de Instrução.');
      if (state.grauInstrucao.codigo === 0) {
        appBase.resposta = await servicoGrauInstrucao.incluir(state.grauInstrucao);
      } else {
        appBase.resposta = await servicoGrauInstrucao.atualizar(state.grauInstrucao);
      }

      apresentarRespostaRedirecionamento('GrauInstrucoes');
    }
    return {
      appBase,
      state,
      salvar,
    };
  },
});
